import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Container } from '../components';

const CustomContainer = styled(Container)`
  justify-content: flex-start;
  padding-top: 5em;
`;

const Title = styled.h2`
  align-self: center;
  text-align: center;
  width: 75vw;
`;

const ServerFull = () => {
  const history = useHistory();

  return (
    <CustomContainer>
      <Title>Sorry, all available rooms are being used.</Title>
      <Title>Please try again later.</Title>
      <Button onClick={() => history.push('/')}>Try Again</Button>
    </CustomContainer>
  );
};

export default ServerFull;
