import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import io from 'socket.io-client';
import Swipe from 'react-easy-swipe';
import styled from 'styled-components';
import Coin from '../components/Coin';
import { Container, ShareButton } from '../components';
import LoadingSpinner from '../components/LoadingSpinner';

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  align-items: center;
`;

const DoneByLegend = styled.a`
  font-size: 0.8em;
  padding: 1.5em;
  text-align: center;
  text-decoration: none;
`;

let socket;

const RoomView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isCoinFlipping, setIsCoinFlipping] = useState(false);
  const [coinValue, setCoinValue] = useState(null);
  const [usersInRoom, setUsersInRoom] = useState(null);
  // const ENDPOINT = 'http://192.168.1.99:8080';
  const ENDPOINT = 'https://letsflipit.herokuapp.com/';
  const { roomId } = useParams();
  const history = useHistory();

  const resolveCoinFlip = (coinFace) => {
    if (coinFace === 0) {
      setCoinValue('animate-tails');
    } else {
      setCoinValue('animate-heads');
    }
    setTimeout(() => {
      setIsCoinFlipping(false);
    }, 1000);
  };

  const flipCoin = () => {
    if (isCoinFlipping) return;
    setIsCoinFlipping(true);
    socket.emit('flipCoin');
  };

  const handleJoinRoom = (room) => {
    window.history.pushState({}, '', room);
    setIsLoading(false);
  };

  useEffect(() => {
    socket = io(ENDPOINT, {
      query: `room=${roomId}`,
    });
    socket.on('joinedRoom', (room) => handleJoinRoom(room));
    socket.on('serverIsFull', () => history.push('/error/server-full'));
    socket.on('roomDoesNotExist', () => history.push('/error/room-doesnt-exist'));
    socket.on('usersActiveInRoom', (roomLength) => setUsersInRoom(roomLength));


    socket.on('flippedCoin', (data) => resolveCoinFlip(data));
    socket.on('flippingCoin', () => setCoinValue('animate-loading'));
    // eslint-disable-next-line
  }, [roomId]);

  if (isLoading) return <LoadingSpinner />;

  return (
    <Container>
      <Swipe onSwipeUp={flipCoin}>
        <Coin onClick={flipCoin} coinClass={coinValue} />
      </Swipe>
      <Footer>
        <ShareButton />
        <p>
          Users in room:
          {' '}
          {usersInRoom}
        </p>
        <DoneByLegend href="https://www.instagram.com/theorhodius/">Artwork by @theorhodius</DoneByLegend>
      </Footer>
    </Container>
  );
};

export default RoomView;
