import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
      box-sizing: border-box;
    }

    #root {
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      position: fixed;
    }

    html, body {
      margin: 0;
      font-size: 0.95em;
      font-family: 'Work Sans', sans-serif;
      font-weight: 400;
    }

    button {
      margin: 0;
      font-size: 0.95em;
      font-family: 'Work Sans', sans-serif;
      font-weight: 400;
    }
`;

export default GlobalStyle;
