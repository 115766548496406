import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Container } from '../components';

const Title = styled.h2`
  align-self: center;
  text-align: center;
  width: 75vw;
`;

const CustomContainer = styled(Container)`
  justify-content: flex-start;
  padding-top: 5em;
`;

const CustomButtom = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px;
`;

const SearchContainer = styled.div`
  display: flex;
`;

const RoomInput = styled.input`
  height: 40px;
  max-width: 80px;
  font-size: 1em;
  margin-right: -0.5px;
  border: 0.1em solid #cdd9ed;
  border-radius: 0.25em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  &:focus {
    outline: none;
    border-color: #007bff;
  };
`;


const RoomDoesntExist = () => {
  useEffect(() => {
    const textInput = document.getElementById('searchRoomId');
    textInput.focus();
  });

  const history = useHistory();

  const navigateTo = () => {
    const roomId = document.getElementById('searchRoomId');
    return history.push(`/${roomId.value}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') return navigateTo();
  };

  return (
    <CustomContainer>
      <Title>The room you're looking for doesnt exist.</Title>
      <h2>Try searching again</h2>
      <SearchContainer>
        <RoomInput
          id="searchRoomId"
          maxLength="2"
          inputMode="numeric"
          pattern="[0-9]*"
          type="text"
          onKeyPress={(e) => handleKeyPress(e)}
        />
        <CustomButtom onClick={navigateTo}>Search</CustomButtom>
      </SearchContainer>
      <h2>Or</h2>
      <Button onClick={() => history.push('/')}>Create Random Room</Button>
    </CustomContainer>
  );
};

export default RoomDoesntExist;
