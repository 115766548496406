import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import HeadsImage from '../assets/coin-heads.png';
import TailsImage from '../assets/coin-tails.png';

export const flipHeads = keyframes`
  from {
    transform: rotateX(360deg);
    -webkit-transform: rotateX(360deg);
    -moz-transform: rotateX(360deg);
  }
  to {
    transform: rotateX(720deg);
    -webkit-transform: rotateX(720deg);
    -moz-transform: rotateX(720deg);
  }
`;

export const flipTails = keyframes`
from {
    transform: rotateX(405deg);
    -webkit-transform: rotateX(405deg);
    -moz-transform: rotateX(405deg);
  }
  to {
    transform: rotateX(540deg);
    -webkit-transform: rotateX(540deg);
    -moz-transform: rotateX(540deg);
  }
`;

export const flipLoading = keyframes`
  from {
    -webkit-transform: rotateX(0);
    -moz-transform: rotateX(0);
    transform: rotateX(0);
  }
  to {
    -webkit-transform: rotateX(360deg);
    -moz-transform: rotateX(360deg);
    transform: rotateX(360deg);
  }
`;

const grow = (coinSize) => keyframes`
  from {
    width: 11em;
    height: 11em;
  }
  to {
    width: ${coinSize}em;
    height: ${coinSize}em;
  }
`;

const shrink = (coinSize) => keyframes`
  from {
    width: ${coinSize}em;
    height: ${coinSize}em;
  }
  to {
    width: 11em;
    height: 11em;
  }
`;

const CoinContainer = styled.div`
  position: relative;
  width: 11em;
  height: 11em;
  margin-bottom: 6rem;
  transform-style: preserve-3d;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  @media screen and (device-aspect-ratio: 40/71) {
    margin-bottom: 12.5rem;
  };
  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    backface-visibility: hidden;
    background-size: contain;
    position: absolute;
  }
  animation: ${({ coinClass }) => {
    const coinMaxSize = window.matchMedia('(device-aspect-ratio: 40/71)') ? 19 : 22.5;

    switch (coinClass) {
      case 'animate-heads':
        return css`${shrink(coinMaxSize)} 0.75s cubic-bezier(1,0,1,0), ${flipHeads} 0.5s`;
      case 'animate-tails':
        return css`${shrink(coinMaxSize)} 0.75s cubic-bezier(1,0,1,0), ${flipTails} 0.4s`;
      case 'animate-loading':
        return css`${grow(coinMaxSize)} 1s cubic-bezier(.48,-0.03,.41,1.43), ${flipLoading} 0.4s linear infinite`;
      default:
        // code block
    }
  }};
  animation-fill-mode: forwards;
`;

const Heads = styled.div`
  background-image: url(${HeadsImage});
`;

const Tails = styled.div`
  background-image: url(${TailsImage});
  transform: rotateY(-180deg);
`;

const Coin = ({ coinClass, onClick }) => (
  <CoinContainer onClick={onClick} coinClass={coinClass}>
    <Heads />
    <Tails />
  </CoinContainer>
);

export default Coin;
