import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Button } from '.';
import copyTextToClipboard from '../helpers/copyToClipboard';

export const fadeInUp = keyframes`
  0% {
    opacity: 1;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
  }
`;

const LinkCopied = styled.p`
  animation: ${fadeInUp} ease-in 0.45s;
  animation-fill-mode: forwards;
  text-align: right;
  text-decoration: none;
`;

const ShareButton = () => {
  const [isLinkCopied, setLinkCopied] = useState(false);

  const handleShareButton = async () => {
    const coinFlipUrl = window.location.href;

    if (navigator.share) {
      await navigator.share({
        text: "Let's flip a coin together\n",
        url: coinFlipUrl,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error in sharing', error));
    } else {
      console.log('system does not support sharing files.');
    }
  };

  const copyShareLink = async () => {
    copyTextToClipboard(window.location.href);

    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 450);
  };

  return (
    <>
      {navigator.share ? (
        <Button onClick={handleShareButton}>
          Share Invite Link
        </Button>
      ) : (
        <>
          {isLinkCopied && <LinkCopied>Link Copied!</LinkCopied>}
          <Button onClick={copyShareLink}>
            Copy Invite Link
          </Button>
        </>
      )}
    </>
  );
};

export default ShareButton;
