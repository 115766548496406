import styled from 'styled-components';

const Button = styled.button`
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #007bff;
  background: #007bff;
  border-radius: 0.25em;
  text-decoration: none;
  color: #fff;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    background-color: #0069d9;
    cursor: pointer;
  }
`;

export default Button;
