import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RoomView from './pages/room';
import ServerFull from './pages/server-full';
import RoomDoesntExist from './pages/room-doesnt-exist';

const Routes = () => (
  <Switch>
    <Route path="/:roomId" exact component={RoomView} />
    <Route path="/" exact component={RoomView} />
    <Route path="/error/server-full" exact component={ServerFull} />
    <Route path="/error/room-doesnt-exist" exact component={RoomDoesntExist} />
  </Switch>
);

export default Routes;
